<template>
  <div class="d-flex justify-center flex-column">
    <div class="dialog-content">
      <div v-if="!aboutWallet" class="earning-rules mt-7">
        <div
          class="rule d-flex align-start mt-5"
          v-for="(rule, index) in rules"
          :key="index"
        >
          <div
            class="d-flex align-center justify-end"
            :style="{ minWidth: '60px' }"
          >
            <block-text
              :text="rule.amount"
              type="body-2"
              weight="bold"
              color="primary"
              :style="{ lineHeight: '18px' }"
            />
            <div class="ml-3">
              <v-img
                class="ttu-coin-img rounded-circle"
                width="24"
                height="24"
                :src="require('@/assets/ttuToken.svg')"
              />
            </div>
          </div>
          <block-text
            :text="$t('earning.rulesArray.' + rule.text)"
            class="ml-5"
            type="body-1"
            color="secondary"
            :style="{ lineHeight: '21px' }"
          />
        </div>
      </div>
      <div v-else class="mt-5">
        <block-text
          :text="$t('earning.aboutWalletText1')"
          type="body-1"
          color="secondary"
          :style="{ display: 'block', lineHeight: '21px' }"
        />
        <block-text
          :text="$t('earning.aboutWalletText2')"
          type="body-1"
          color="secondary"
          class="mt-5"
          :style="{ display: 'block', lineHeight: '21px' }"
        />
        <block-text
          :text="$t('earning.aboutWalletText3')"
          type="body-1"
          color="secondary"
          class="mt-5"
          :style="{ display: 'block', lineHeight: '21px' }"
        />
      </div>
    </div>

    <div class="dialog-actions mt-12 d-flex justify-end">
      <v-btn
        @click="toggleAboutWallet"
        outlined
        rounded
        :elevation="0"
        text
        :color="'primary'"
        :ripple="false"
        :plain="false"
        class="body-1 px-10 font-weight-bold text-center mr-5"
        >{{ aboutWallet ? passedData.ctaText3 : passedData.ctaText1 }}
      </v-btn>
      <v-btn
        @click="closeDialog"
        outlined
        rounded
        :elevation="0"
        text
        :color="'primary'"
        :ripple="false"
        :plain="false"
        class="body-1 px-10 font-weight-bold text-center"
        >{{ passedData.ctaText2 }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    passedData: {
      default: null,
    },
  },
  data() {
    return {
      aboutWallet: false,
      rules: [
        { amount: "0.1", text: "forEveryPostYouView" },
        { amount: "1", text: "forEachLikeOnYourPost" },
        { amount: "1", text: "forEachLikeOnYourComment" },
        { amount: "1", text: "forEveryShareOfYourPost" },
        { amount: "1", text: "everyTimeSomeoneTagsYou" },
        { amount: "2", text: "forEachCommentOnYourPost" },
        { amount: "4", text: "forEveryNewFollower" },
        { amount: "4", text: "inviteFriends" },
        { amount: "4", text: "invitedByFriend" },
        { amount: "12", text: "watchContent" },
        { amount: "50", text: "whenYouRegister" },
      ],
    };
  },
  methods: {
    closeDialog() {
      this.$eventHub.$emit("toggleDialog", {
        isOpen: false,
      });
    },
    toggleAboutWallet() {
      this.aboutWallet = !this.aboutWallet;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  svg {
    width: 24px;
    height: 24px;
  }
}
</style>
